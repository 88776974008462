<template>
  <div >
    
    <v-layout wrap justify-center >
        <v-flex xs12 sm12 md12 lg12 pb-0 mb-0>
            <AppHeader class="pb-0 mb-0" />
          </v-flex>
      <v-flex xs12 sm11 md10 align-self-center style="min-height:90vh !important;">
        <v-layout wrap pa-12 style="color: #000">
          <v-flex xs12 align-self-center pa-2 class="poppinsregular">
            <span class="poppinsbold" style="font-size: 24px"
              >Privacy Policy</span
            >
          </v-flex>
          <v-flex text-left xs12 align-self-center pa-2>
            <span style="letter-spacing: 0.91px">
              Our privacy policy will help you understand what information we
              collect at Wild-Watch, how Wild-Watch uses it, and what choices
              you have. Wild-Watch built the Wild-Watch app as a free app. This
              SERVICE is provided by Wild-Watch at no cost and is intended for
              use as is. Our privacy policy will help you understand what
              information we collect at Wild-Watch, how Wild-Watch uses it, and
              what choices you have. Wild-Watch built the Wild-Watch app as a
              free app. This SERVICE is provided by Wild-Watch at no cost and is
              intended for use as is. If you choose to use our Service, then you
              agree to the collection and use of information in relation with
              this policy. The Personal Information that we collect are used for
              providing and improving the Service. We will not use or share your
              information with anyone except as described in this Privacy
              Policy. The terms used in this Privacy Policy have the same
              meanings as in our Terms and Conditions, which is accessible in
              our website, unless otherwise defined in this Privacy Policy.
            </span>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
import AppHeader from "@/components/Common/appHeaderP";
import axios from "axios";
export default {  
  components: {
    AppHeader,
  },
  data() {
    return {
      appLoading: false,
      ServerError: false,
      showSnackBar: false,
      timeout: 5000,
      msg: null,
      pageContent: null,
      email: null,
      phone: null,
      pageData: [],
      name: null,
      text: null,
    };
  },
  beforeMount() {
    this.getData();
  },
  methods: {
    getData() {
      this.appLoading = true;
      axios({
        method: "GET",
        url: "/policy/view",
        params: {
          type: "all",
          name: "Privacy Policy",
        },
      })
        .then((response) => {
          if (response.data.status) {
            this.appLoading = false;
            this.pageData = response.data.data;
            this.name = response.data.data.name;
            this.text = response.data.data.content;
            // alert(this.text)
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          console.log(err);
        });
    },
    formatDate(item) {
      var dt = new Date(item);
      var day = dt.getDate();
      var year = dt.getFullYear();
      var hours = dt.getHours();
      var minutes = dt.getMinutes();
      dt = dt.toString();
      var ampm = hours >= 12 ? "pm" : "am";
      hours = hours % 12;
      hours = hours ? hours : 12;
      minutes = minutes < 10 ? "0" + minutes : minutes;
      var strTime =
        day +
        " " +
        dt.slice(4, 7) +
        " " +
        year +
        " " +
        hours +
        ":" +
        minutes +
        " " +
        ampm;
      return strTime;
    },
  },
};
</script>
<style>
#pageContent p img {
  max-width: 80vw;
  max-height: 200vh;
}
</style>
