<template>
  <div>
    <ServerError v-if="ServerError" />
    <v-snackbar
      v-model="showSnackBar"
      :color="appColor"
      right
      :timeout="timeout"
    >
      <v-layout wrap justify-center>
        <v-flex text-left align-self-center>{{ msg }}</v-flex>
        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showSnackBar = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <v-navigation-drawer
      v-if="appUser.role == 'official'"
      v-model="sideNav"
      fixed
      temporary
      width="220px"
      class="hidden-md-and-up"
      color="#FAFAFA"
    >
      <v-layout wrap justify-center>
        <v-flex xs12 text-center>
          <!-- <span class="itemHeading1"
        >WILD WATCH
</span> -->
          <Logo />
        </v-flex>
        <v-flex xs12 text-center pa-2 pl-4 text-uppercase align-self-center>
          <v-list dense nav>
            <v-list-item
              v-for="item in items"
              :key="item.title"
              link
              :to="item.path"
            >
              <v-list-item-icon>
                <v-icon>{{ item.icon }}</v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title>{{ item.title }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-flex>
      </v-layout>
    </v-navigation-drawer>
    <v-navigation-drawer
      v-if="appUser.role == 'admin'"
      v-model="sideNav"
      fixed
      temporary
      width="220px"
      class="hidden-md-and-up"
      color="#FAFAFA"
    >
      <v-layout wrap justify-center>
        <v-flex xs12 text-center>
          <!-- <span class="itemHeading1"
        >WILD WATCH
</span> -->
          <Logo />
        </v-flex>
        <v-flex xs12 text-center pa-2 pl-4 text-uppercase align-self-center>
          <v-list dense nav>
            <v-list-item
              v-for="item in adminItems"
              :key="item.title"
              style="letter-spacing: 0.63px"
              link
              class="pr-0"
              :to="item.path"
            >
              <v-list-item-icon>
                <v-icon class="pr-3" color="#9F9F9F">{{ item.icon }}</v-icon>
                <v-list-item-title
                  style="
                    font-size: 14px;
                    font-family: poppinsmedium;
                    color: #9f9f9f;
                  "
                  >{{ item.title }}</v-list-item-title
                >
              </v-list-item-icon>
            </v-list-item>
            <!-- <v-list-item
              style="letter-spacing: 0px;margin-right: 0px!important;"
              link
              
             
            >
              <v-list-item-icon style="margin-right: 0px!important;">
                <v-icon class="pr-3" color="#9F9F9F">mdi-car</v-icon>
                <v-list-item-title
                class="mr-3"
                  style="
                    font-size: 14px;
                    font-family: poppinsmedium;
                    color: #9f9f9f;
                    margin-right: 0px!important;
                  "
                  >Dynamic Data</v-list-item-title
                >
                <v-icon class="" size="16" color="#9F9F9F">mdi-arrow-down-drop-circle</v-icon>
              </v-list-item-icon>
              
            </v-list-item> -->
            <v-list-group
              class="pt-0 mt-0"
              v-for="item in dynamic"
              :key="item.title"
              no-action
            >
              <template v-slot:activator>
                <v-list-item-icon style="margin-right: -15px !important">
                  <v-icon class="pr-3" color="#9F9F9F">{{ item.icon }}</v-icon>
                  <v-list-item-title
                    style="
                      font-size: 14px;
                      font-family: poppinsmedium;
                      color: #9f9f9f;
                    "
                    v-text="item.title"
                  ></v-list-item-title>
                </v-list-item-icon>
              </template>

              <v-list-item
                v-for="child in item.items"
                :key="child.title"
                style="letter-spacing: 0.63px"
                class="pr-0"
                :to="child.path"
              >
                <v-list-item-icon>
                  <!-- <v-icon class="pr-3" color="#9F9F9F">{{ child.icon }}</v-icon> -->
                  <v-list-item-title
                    class="pr-0"
                    style="
                      font-size: 14px;
                      font-family: poppinsmedium;
                      color: #9f9f9f;
                    "
                    v-text="child.title"
                  ></v-list-item-title>
                </v-list-item-icon>
              </v-list-item>
            </v-list-group>
          </v-list>
        </v-flex>
      </v-layout>
    </v-navigation-drawer>
    <v-navigation-drawer
      v-model="sideNavRight"
      right
      fixed
      temporary
      width="220px"
      class="hidden-md-and-up"
      color="#FAFAFA"
    >
      <v-layout wrap>
        <v-flex v-for="(item, i) in headerItems" :key="i" xs12 text-center>
          <v-layout wrap>
            <v-flex
              xs12
              @click="changeRoute(item)"
              :class="
                $route.query.id == item.nameValue
                  ? 'appHeaderActiveClass'
                  : 'appHeaderNormalClass' &&
                    !$route.query.id &&
                    item.nameValue == 'activeCases'
                  ? 'appHeaderActiveClass'
                  : 'appHeaderNormalClass'
              "
              style="
                text-decoration: none;
                cursor: pointer;
                letter-spacing: 0px;
              "
            >
              <span
                :class="
                  $vuetify.breakpoint.name == 'md'
                    ? 'font-size-md'
                    : 'font-size-other'
                "
              >
                {{ item.title }}:
              </span>
              <v-btn
                color="#ededed"
                elevation="0"
                width="25px"
                height="23px"
                fab
                ><span class="pa-1" style="color: #000; font-size: 10px">{{
                  item.value
                }}</span>
              </v-btn>
            </v-flex>
            <v-flex xs12 pt-3>
              <v-progress-linear
                height="5px"
                v-if="
                  $route.query.id == item.nameValue ||
                  (!$route.query.id && item.nameValue == 'activeCases')
                "
                value="100"
                color="#ededed"
              ></v-progress-linear>
            </v-flex>
          </v-layout>
        </v-flex>
      </v-layout>
    </v-navigation-drawer>

    <v-app-bar color="#FFFFFF" dense dark class="hidden-md-and-up">
      <v-app-bar-nav-icon @click.native="sideNav = !sideNav">
              <router-link to="/">
        <v-icon color="#000">mdi-menu</v-icon>
              </router-link>
        
      </v-app-bar-nav-icon>

       <v-spacer></v-spacer>
 
  

      <v-menu left bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon v-bind="attrs" v-on="on">
            <v-icon color="#000">mdi-dots-vertical</v-icon>
          </v-btn>
        </template>

        <v-card tile flat :elevation="0" max-width="250px">
          <v-layout wrap justify-center py-4>
            <v-flex pt-4 xs12 text-center>
              <span
                style="
                  font-family: poppinssemibold;
                  font-size: 16px;
                  color: #000000;
                "
              >
                Welcome
              </span>
            </v-flex>
            <v-flex pt-2 xs12 text-center>
              <v-icon color="#CCB4B4" size="30">
                mdi-account-circle-outline
              </v-icon>
            </v-flex>
            <v-flex xs12 text-center>
              <router-link to="/profile">
                <span
                  v-if="appLogin"
                  style="
                    font-family: poppinsmedium;
                    font-size: 13px;
                    color: #1e1b1b;
                  "
                >
                  <span>
                    {{ appUser.fullName }}
                  </span>
                </span>
              </router-link>
            </v-flex>
            <v-flex xs12 pt-4 text-center>
              <v-btn
                v-if="!appLogin"
                color="#0000001f"
                outlined
                tile
                small
                :ripple="false"
                to="/login"
              >
                <span
                  style="font-family: poppinssemibold; font-size: 14px"
                  class="appColor"
                >
                  LOGIN
                </span>
              </v-btn>
              <v-btn
                v-if="appLogin"
                color="#0000001f"
                outlined
                tile
                small
                :ripple="false"
                @click="appLogout"
              >
                <span
                  style="font-family: poppinssemibold; font-size: 14px"
                  class="appColor"
                >
                  LOGOUT
                </span>
              </v-btn>
            </v-flex>
          </v-layout>
        </v-card>
      </v-menu>
    </v-app-bar>

    <v-app-bar
      color="#FFFFFF"
      dense
      flat
      style="padding-left: 0px !important"
      class="hidden-sm-and-down"
      height="60px"
    >
      <!-- <v-app-bar-nav-icon></v-app-bar-nav-icon> -->

      <v-layout wrap fill-height>
        <v-flex xs7 text-left align-self-center>
          <v-layout wrap>
            <v-flex xs12 sm4>
              <router-link to="/">
              <Logo />
              </router-link>
            </v-flex>
          </v-layout>
        </v-flex>
        <v-flex xs5 align-self-center text-left>
          <v-layout wrap>
            <v-flex>
<v-spacer></v-spacer> </v-flex>

            <v-flex xs2 align-self-center text-right v-if="appLogin">
              <AccountMenu />
            </v-flex>
          </v-layout>
        </v-flex>
      </v-layout>
      <!-- <v-menu left bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon v-bind="attrs" v-on="on">
            <v-icon color="#000">mdi-dots-vertical</v-icon>
          </v-btn>
        </template>

        <v-list>
          <v-list-item v-for="n in 5" :key="n" @click="() => {}">
            <v-list-item-title>Option {{ n }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu> -->
    </v-app-bar>
  </div>
</template>
n
<script>
import axios from "axios";
import AccountMenu from "./accountMenu";
// import AppNotifications from "@/views/Notifications/appNotification";
import Socket from "../../Sockets/socket";

export default {
  components: {
    // AppNotifications,
    AccountMenu,
  },
  data() {
    return {
      ServerError: false,
      msg: null,
      showSnackBar: false,
      timeout: 5000,
      searchKey: this.$route.query.searchKey
        ? this.$route.query.searchKey
        : null,
      sideNav: false,
      sideNavRight: false,
      adminItems: [
        {
          title: "Dashboard",
          icon: "mdi-view-dashboard",
          path: "/Admin/admindashboard",
        },
        { title: "My Details", icon: "mdi-account", path: "/Admin/myDetails" },
        { title: "Teams", icon: "mdi-account-group", path: "/Admin/teams" },
        { title: "Forms", icon: "mdi-form-select", path: "/Admin/forms" },
        {
          title: "Notifications",
          icon: "mdi-bell",
          path: "/Admin/notifications",
        },
        { title: "Public", icon: "mdi-account-group", path: "/Admin/public" },
      ],

      dynamic: [
        {
          title: "Dynamic Data",
          icon: "mdi-folder",
          visinle: false,
          items: [
            {
              title: "Scenerios",
              icon: "mdi-account-group",
              path: "/Admin/dynamicData/scenerios",
            },
            {
              title: "Animals",
              icon: "mdi-account-group",
              path: "/Admin/dynamicData/animals",
            },
            {
              title: "Mitigations",
              icon: "mdi-account-group",
              path: "/Admin/dynamicData/mitigations",
            },
            {
              title: "Release Types",
              icon: "mdi-account-group",
              path: "/Admin/dynamicData/releaseTypes",
            },
            {
              title: "Land Types",
              icon: "mdi-account-group",
              path: "/Admin/dynamicData/landTypes",
            },
            {
              title: "Animal Activities",
              icon: "mdi-account-group",
              path: "/Admin/dynamicData/animalActivities",
            },
          ],
        },
      ],
      items: [
        {
          title: "Dashboard",
          icon: "mdi-view-dashboard",
          path: "/Officers/dashboard",
        },
        {
          title: "My Details",
          icon: "mdi-account",
          path: "/Officers/myDetails",
        },
        { title: "Teams", icon: "mdi-account-group", path: "/Officers/teams" },
        { title: "Forms", icon: "mdi-form-select", path: "/Officers/forms" },
        {
          title: "Notifications",
          icon: "mdi-bell",
          path: "/Officers/notifications",
        },
        {
          title: "Officers",
          icon: "mdi-account-circle",
          path: "/Officers/officers",
        },
        {
          title: "Merge Cases",
          icon: "mdi-call-merge",
          path: "/Officers/mergeCases",
        },
        {
          title: "Analytics",
          icon: "mdi-home-analytics",
          path: "/Officers/analytics",
        },
      ],
      headerItems: [
        {
          title: "Active Cases",
          value: null,
          path: "/?id=activeCases",
          nameValue: "activeCases",
        },

        {
          title: "Assigned Cases",
          value: null,
          path: "/?id=assignedCases",
          nameValue: "assignedCases",
        },
        {
          title: "Captured Cases",
          value: null,
          path: "/?id=capturedCases",
          nameValue: "capturedCases",
        },
        {
          title: "Resolved Cases",
          value: null,
          path: "/?id=resolvedCases",
          nameValue: "resolvedCases",
        },
      ],
      analytics: {},
      mergeValue: null,

      // socket Variables
      role: null,
      socketType: null,
      newData: {},
    };
  },
  beforeMount() {
    if (this.searchKey) this.appSearch();
    else this.getData();
  },
  mounted() {
    this.socketData();
    if (this.role == "admin") {
      this.assignAnalytics(this.newData);
    }
    // this.getSearch()
  },
  computed: {
    appLogin() {
      return this.$store.state.isLoggedIn;
    },
    appUser() {
      return this.$store.state.userData;
    },
    counterData() {
      return this.$store.state.counterData;
    },
    appNotification() {
      if (this.$store.state.notification == undefined) {
        return 0;
      } else {
        return this.$store.state.notification;
      }
    },
  },
  watch: {
    counterData: {
      handler: function () {
        // console.log(this.storage);
        this.assignAnalytics(this.counterData);
      },
      deep: true,
    },
    // searchKey() {
    //   this.assignAnalytics(this.counterData);
    // },
    // role() {
    //   if (this.role == "official") {
    //     this.assignAnalytics(this.newData)
    //   }
    // },
    //  socketType() {
    //   if (this.socketType == "resolveReport"|| this.socketType == "reportRelease") {
    //     this.assignAnalytics(this.newData)
    //   }
    // },
  },
  sockets: {
    connect() {
      // Fired when the socket connects.
      this.isConnected = true;
    },

    disconnect() {
      this.isConnected = false;
    },

    // Fired when the server sends something on the "messageChannel" channel.
    messageChannel(data) {
      this.socketMessage = data;
    },
  },
  methods: {
    appLogout() {
      this.$store.commit("logoutUser", true);
    },
    loadData() {
      var newData = {};
      newData = this.counterData;
      console.log(newData, "newData");
      this.assignAnalytics(newData);
    },

    assignAnalytics(analytics) {
      this.headerItems[0].value = analytics.activeCases;
      this.headerItems[1].value = analytics.assignedCases;
      this.headerItems[2].value = analytics.capturedCases;
      this.headerItems[3].value = analytics.resolvedCases;
    },
    socketData() {
      Socket.countFunction(this); // <---read data
    },
    getData() {
      this.appLoading = true;
      axios({
        method: "POST",
        url: "/user/dashboard",
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status == true) {
            this.analytics = response.data.count;
            // this.$store.commit("counterData", response.data.count);
            this.assignAnalytics(this.analytics);
            this.$store.commit(
              "changeNotification",
              response.data.unreadNotification
            );
            this.mergeValue = this.analytics.mergeSuggestion;
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = false;
          }
        })
        .catch((err) => {
          this.ServerError = true;

          this.appLoading = false;
          console.log(err);
        });
    },
    changeRoute(item) {
      if (this.$store.state.userRole == "admin") {
        this.$router.push({
          path: "/admin/adminDashboard",
          query: {
            id: this.$route.query.id != item.nameValue ? item.nameValue : null,
            searchKey: this.$route.query.searchKey,
          },
        });
      } else if (this.$store.state.userRole == "official") {
        this.$router.push({
          path: "/officers/dashboard",
          query: {
            id: this.$route.query.id != item.nameValue ? item.nameValue : null,
            searchKey: this.$route.query.searchKey,
          },
        });
      }
    },
    appSearch() {
      if (this.$route.query.searchKey == this.searchKey) return;
      this.$router.push({
        path: "",
        query: {
          id: this.$route.query.id,
          searchKey: this.searchKey,
        },
      });
    },
  },
};
</script>

<style>
.appHeaderActiveClass {
  font-family: sofiaProSemiBold;
  color: #000 !important;
}
.appHeaderNormalClass {
  font-family: sofiaProRegular;
  color: #838383 !important;
}

.v-toolbar__content,
.v-toolbar__extension {
  padding: 4px 0px !important;
}

.font-size-md {
  font-size: 12px;
}
.font-size-other {
  font-size: 16px;
}
</style>
